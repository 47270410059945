import React, {Fragment} from "react";
import {Helmet} from 'react-helmet-async';
import {ContactHeader} from "../components/header/ContactHeader";
import {PlainHeader} from "../components/header/PlainHeader";
import {DenseHero} from "../components/splash/hero/DenseHero";
import {DenseFeature} from "../components/splash/feature/DenseFeature";
import {Amenities} from "../components/splash/amenities/Amenities";
import {DriveGallery} from "@renaissancerentals/renaissance-component";
import {PageProps} from "../data/PageProps";
import {propertyId} from "../services/DataService";

export const Mccormick: React.FC<PageProps> = ({contact}) => {
    return (
        <Fragment>
            <Helmet>
                <title>All Utilities Included - SummerHouse 2 bedroom for rent - Bloomington Indiana</title>
                <meta name="description"
                      content="Brand New Two Bedroom town home rental in Bloomington Indiana, all utilities included, gym & pool on-site, close to IU"/>
            </Helmet>
            <ContactHeader contact={contact}/>
            <PlainHeader contact={contact}/>
            <main className="splash">
                <DenseHero/>
                <DenseFeature/>
                <Amenities/>
                <div className="container">
                    <h2 className="heading"><span className="emphasized"><i>Photos</i></span></h2>
                    <DriveGallery driveId="1Ss5PW-mht0hhBa6ddjhn5K2K1TZk5wUy" showName={false} initialSize={9} propertyId={propertyId}/>
                </div>
            </main>
        </Fragment>
    );
}