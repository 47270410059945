import React from 'react';
import './assets/MainHeader.scss';
import {Logo} from "../logo/Logo";
import {HeaderProps} from "./ContactHeader";
import {formatPhoneNumber} from "@renaissancerentals/renaissance-component";

export const PlainHeader: React.FC<HeaderProps> = ({contact}) => {

    return (<header className="header-main">
            <div className="div-mobile">
                <div className="header">
                    <div className="left"/>
                    <Logo/>
                    <div className="right"/>
                </div>
            </div>
            <div className="div-main">
                <div className="header">
                    <div className="left"/>
                    <Logo/>
                    <div className="right">
                        <p>Call or text today!<br/><a
                            href={"tel:" + contact.phone}>{formatPhoneNumber(contact.phone)}</a></p>
                    </div>
                </div>
            </div>
        </header>
    )
};
