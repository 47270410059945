import React from 'react';
import './assets/HeroSection.scss';

import {Video} from "@renaissancerentals/renaissance-component";

export const HeroSection: React.FC<HeroSectionProps> = ({videoUrl}) => {
    return (
        <section className="section-hero">
            <Video url={videoUrl}/>
        </section>
    );
}

export interface HeroSectionProps {
    videoUrl: string;
}