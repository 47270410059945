import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import {NotFound} from "./pages/NotFound";
import {Home} from "./pages/Home";
import "./assets/App.scss";
import HttpsRedirect from "react-https-redirect";
import {LongTerm} from "./pages/LongTerm";
import {Mccormick} from "./pages/Mccormick";
import {ShortTerm} from "./pages/ShortTerm";
import {Contact, getProperty, PropertyDetails} from "@renaissancerentals/renaissance-component";
import {defaultContact} from "@renaissancerentals/renaissance-component/lib/contact/data/Contact";
import {FloorplanPage} from "./pages/FloorplanPage";
import {propertyId, shortTermPropertyId} from "./services/DataService";

const App: React.FC = () => {
    const [property, setProperty] = useState<PropertyDetails>({} as PropertyDetails);
    const [shortTerm, setShortTerm] = useState<PropertyDetails>({} as PropertyDetails);
    const [isLoading, setIsLoading] = useState(true);
    const [contact, setContact] = useState<Contact>(defaultContact);
    useEffect(() => {

        Promise.all([getProperty(propertyId), getProperty(shortTermPropertyId)]).then(data => {
            setProperty(data[0]);
            setShortTerm(data[1]);
            setContact({
                phone: data[0].phone,
                email: data[0].email,
                address: data[0].address + ", " + data[0].zipcode
            })

        }).finally(() => setIsLoading(false));
    }, []);
    return (
        <HttpsRedirect>
            <HelmetProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<Home contact={contact} isLoading={isLoading} property={property}/>}/>

                        <Route path="/short-term"
                               element={<ShortTerm contact={contact} isLoading={isLoading} property={shortTerm}/>}/>

                        <Route path="/long-term"
                               element={<LongTerm contact={contact} isLoading={isLoading} property={property}/>}/>


                        <Route path="/mccormick"
                               element={<Mccormick contact={contact} isLoading={isLoading} property={property}/>}/>
                        <Route path="mccormick-promo"
                               element={<Mccormick contact={contact} isLoading={isLoading} property={property}/>}/>
                        <Route path="/floorplans/:floorplanId"
                               element={<FloorplanPage isLoading={isLoading} property={property} contact={contact}/>}/>

                        <Route path="*" element={<NotFound/>}/>
                    </Routes>

                </Router>
            </HelmetProvider>
        </HttpsRedirect>
    );
};

export default App;
