import React from 'react';
import './assets/Amenities.scss';

export const Amenities = () => {
    return (
        <section className="home__section--amenities">
            <div className="container">
                <div className="dense-amenities-left">
                    <h2>Amenities</h2>
                    <ul>
                        <li>All utilities included + WiFi + Cable</li>
                        <li>Washer & dryer included in-unit</li>
                        <li>Fully equipped kitchen with double pantry</li>
                        <li>Open concept living area</li>
                        <li>Covered porch</li>
                        <li>Luxury Vinyl-Plank flooring</li>
                    </ul>
                </div>
                <div className="amenities-right">
                    <h2 className="main">&nbsp;</h2>
                    <ul>
                        <li>Soft-close cabinets</li>
                        <li>Keyless-entry locks</li>
                        <li>Free parking</li>
                        <li>Fitness Center</li>
                        <li>Indoor & outdoor lounge spaces with WiFi</li>
                        <li>Garage option *ask for pricing & availability</li>
                    </ul>

                </div>
            </div>
        </section>
    );
}