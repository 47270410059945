import React from "react";
import {Contact, formatPhoneNumber, InfoHeader} from "@renaissancerentals/renaissance-component";

export const ContactHeader: React.FC<HeaderProps> = ({contact}) => {
    return (
        <div className="mobile">
            <InfoHeader>
                <p>Call or text today <a href={"tel:" + contact.phone}>{formatPhoneNumber(contact.phone)}</a></p>
            </InfoHeader>
        </div>
    );
};

export interface HeaderProps {
    contact: Contact;
}

