import React from 'react';
import './assets/Amenities.scss';

export const AmenitiesSection: React.FC<AmenitiesSectionProps> = ({children, title, amenities}) => {

    return (
        <section className="section-amenities">
            <div className="container">
                <h2 className="heading"><span className="emphasized">{title}</span></h2>
                <ul>
                    {amenities.map(amenity =>
                        (<li key={amenity}>{amenity}</li>)
                    )}
                </ul>
                {
                    children
                }
            </div>
        </section>
    );
};

export interface AmenitiesSectionProps {
    title: string;
    amenities: string[];
    children?: any;
}
