import React from 'react';
import {NavProps} from "./NavProps";

export const HeaderNav: React.FC<HeaderNavProps> = (
    {
        primaryNavs, secondaryNavs,
        modalClose, children
    }) => {

    return (
        <nav>
            {primaryNavs.map(link => (
                <h3 key={link.text}>
                    <a href={link.anchor} onClick={() => {
                        if (link.handleRef) link.handleRef();
                        modalClose()
                    }}>
                        {link.text}
                    </a>
                </h3>
            ))}
            {children}
            {secondaryNavs.map(link => (
                <h3 key={link.text}>
                    <a href={link.anchor} onClick={() => {
                        if (link.handleRef) link.handleRef();
                        modalClose()
                    }}>
                        {link.text}
                    </a>
                </h3>
            ))}
        </nav>
    )
};


export interface HeaderNavProps extends NavProps {
    modalClose: () => void;
}

