import React from "react";
import {
    ApplicationModal,
    Contact,
    ContactModal,
    Footer,
    PropertyDetails, trackContactClicked
} from "@renaissancerentals/renaissance-component";
import {FooterNav} from "../nav/FooterNav";
import {ContactHeader} from "../header/ContactHeader";
import {MainHeader, MainHeaderProps} from "../header/MainHeader";

export const MainLayout: React.FC<MainLayoutProps> = (
    {
        primaryNavs, secondaryNavs, leftLink,
        property,
        handleRefToMap, contact,
        setShowContactModal, setShowApplicationModal, showContactModal, showApplicationModal,
        contactClickHandler,
        children
    }) => {

    return (<>
            <ContactModal showContactModal={showContactModal}
                          contactModalCloseHandler={() => setShowContactModal(false)}
                          conversionTrackingId1={property.conversionTrackingId1}
                          conversionTrackingId2={property.conversionTrackingId2}
                          to={property.email}
                          contactNumber={property.phone}
                          subject={"Message from " + property.name}
                          propertyId="summer-house"
            />
            <ApplicationModal showApplicationModal={showApplicationModal}
                              applicationModalCloseHandler={() => setShowApplicationModal(false)}
                              propertyName={property.name}
                              propertyEmail={property.email}
                              contactClickHandler={() => {
                                  setShowApplicationModal(false);
                                  setShowContactModal(true);
                                  trackContactClicked("summer-house");
                              }}/>
            <ContactHeader contact={contact}/>
            <MainHeader primaryNavs={primaryNavs} secondaryNavs={secondaryNavs} leftLink={leftLink} contact={contact}
                        contactClickHandler={contactClickHandler}/>
            <main id="home">
                {children}
            </main>
            <Footer nav={<FooterNav handleRefToApply={() => setShowApplicationModal(true)}
                                    handleRefToMap={handleRefToMap} contact={contact}
                                    handleRefToContact={() =>{
                                        setShowContactModal(true);
                                        trackContactClicked("summer-house");
                                    }}/>}/>
        </>
    );

}

export interface MainLayoutProps extends MainHeaderProps {
    property: PropertyDetails
    showContactModal: boolean;
    showApplicationModal: boolean;
    setShowApplicationModal: (show: boolean) => void;
    setShowContactModal: (show: boolean) => void;
    handleRefToMap: () => void;
    contact: Contact;
    children?: any;
}
