import React from 'react';
import './assets/Action.scss';
import magnifyIcon from "./assets/MagnifyingGlass.png";
import applyIcon from "./assets/Apply.png";
import calendarIcon from "./assets/Calendar.png";
import {Link} from "react-router-dom";
import {FloorplanCardSkeleton, FloorplanCardSlider, FloorplanDetails} from "@renaissancerentals/renaissance-component";
import {Button} from "@contentmunch/muncher-ui";
import {propertyId} from "../../services/DataService";

export const ActionSection: React.FC<ActionSectionProps> = (
    {
        handleRefToApply,
        handleRefToContact,
        handleRefToFurnishedFloorPlan,
        floorplans,
        isLoading,
        handleRefToUnfurnishedFloorPlan
    }) => {

    return (
        <section className="section-action">
            <div className="container">
                <div className="actions">
                    <div className="action">
                        <div className="action--icon">
                            <a href="#floorPlan" onClick={handleRefToFurnishedFloorPlan}>
                                <img src={magnifyIcon} alt="search icon" height={40}/>
                            </a>
                        </div>
                        <div className="action-content">
                            <h2><a href="#floorPlan" onClick={handleRefToFurnishedFloorPlan}>Find Your Apartment or
                                townhome</a></h2>
                            <p>Spacious 1, 2, or 3 bedroom apartments and townhomes are available <a
                                href="#furnished-floorplan" onClick={handleRefToFurnishedFloorPlan}>fully
                                furnished</a> or <a href="#unfurnished-floorplan"
                                                    onClick={handleRefToUnfurnishedFloorPlan}>unfurnished</a>.</p>
                        </div>
                    </div>
                    <div className="action">
                        <div className="action--icon">
                            <a href="#contact" onClick={handleRefToContact}>
                                <img src={calendarIcon} alt="schedule icon" height={40}/>
                            </a>
                        </div>
                        <div className="action-content">
                            <h2><a href="#contact" onClick={handleRefToContact}>Schedule a Tour</a></h2>
                            <p>
                                Ask a question or schedule a tour, we’re here for you all week, every week.
                            </p>
                        </div>
                    </div>
                    <div className="action">
                        <div className="action--icon">
                            <a href="#apply" onClick={handleRefToApply}> <img src={applyIcon} alt="apply icon"
                                                                              height={40}/></a>
                        </div>
                        <div className="action-content">
                            <h2><a href="#apply" onClick={handleRefToApply}>Apply Online &raquo;</a></h2>
                            <p>
                                It’s almost yours; just fill out the Free App and we’ll be in touch soon!
                            </p>
                            <div><Button variant={"primary"}><Link to="/short-term"><i>Looking for Short Term
                                rentals?</i>&nbsp;click
                                here</Link></Button></div>

                        </div>
                    </div>
                </div>
                <div className="main">
                    {isLoading ? <FloorplanCardSkeleton/> :
                        <FloorplanCardSlider size="small" propertyId={propertyId}
                                             floorplans={floorplans.filter(floorplan => floorplan.active).filter(floorplan => floorplan.featured)}/>}
                </div>

            </div>
        </section>
    );
};

export interface ActionSectionProps {
    handleRefToApply: () => void;
    handleRefToFurnishedFloorPlan: () => void;
    handleRefToUnfurnishedFloorPlan: () => void;
    handleRefToContact: () => void;
    floorplans: FloorplanDetails[];
    isLoading: boolean;
}

