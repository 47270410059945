import React from 'react';
import './assets/Footer.scss';
import bike from './assets/bike.png';
import {HeaderProps} from "../../header/ContactHeader";
import {formatPhoneNumber} from "@renaissancerentals/renaissance-component";

export const Footer: React.FC<HeaderProps> = ({contact}) => {
    return (
        <section className="home-section-footer">
            <footer className="mobile">
                <p>SUMMERHOUSE AT INDIANA</p>
                <p>4501 East Third Street, Bloomington, IN 47401</p>
                <p><a href={"tel:" + contact.phone}>{formatPhoneNumber(contact.phone)}</a></p>
            </footer>
            <footer className="main">
                <p><img src={bike} width={120} alt="bicycle"/>SUMMERHOUSE AT INDIANA | 4501 East Third Street,
                    Bloomington, IN 47401 | <a
                        href={"tel:" + contact.phone}>&nbsp;{formatPhoneNumber(contact.phone)}</a>
                </p>
            </footer>
        </section>
    );
}