import React from 'react';
import './assets/DenseFeature.scss';

export const DenseFeature = () => {
    return (
        <section className="home-section-dense-feature">
            <div className="dense-feature--left">
                <h1><i>We Make Living Easy</i></h1>
                <ul>
                    <li><span className="dense-feature-heading">no setup</span><br/>
                        Simplify your move<br/>No utility setup required
                    </li>
                    <li><span className="dense-feature-heading">same cost every month</span><br/>
                        No price fluctuations for higher utility costs in summer and winter months,
                        and no caps on utilities. Easily split the bill with a roommate, just divide by 2
                    </li>
                    <li><span className="dense-feature-heading">no deposit or fees</span><br/>
                        Move in and out without additional fees, service calls, or setup times
                    </li>
                    <li><span className="dense-feature-heading">simple service calls</span><br/>
                        Don’t spend your valuable time waiting on hold for service issues<br/>
                        – we’ll take care of it for you
                    </li>
                </ul>
            </div>

            <div className="dense-feature--right">
                <ul>
                    <li>water</li>
                    <li>electricity</li>
                    <li>internet</li>
                    <li>cable</li>
                    <li className="plus">+ gym</li>

                    <li>$55</li>
                    <li>$150</li>
                    <li>$50</li>
                    <li>$60</li>
                    <li>$10</li>
                </ul>
                <hr/>
                <ul>
                    <li>Utilities & Services</li>
                    <li className="plus">+ base rent</li>
                    <li>$325</li>
                    <li>$1400</li>
                </ul>
                <hr/>
                <ul>
                    <li className="total">total</li>
                    <li className="total">$1725</li>
                </ul>
            </div>


        </section>
    );
}