import React from 'react';
import './assets/DenseHero.scss';
import { Video} from "@renaissancerentals/renaissance-component";
import {Button} from "@contentmunch/muncher-ui";

export const DenseHero = () => {
    return (
        <section className="section__hero--dense">
            <div className="hero--left">
                <div className="hero--banner">
                    <div className="banner--action">
                        <h2 className="feature-btn"><Button variant={"primary"}><a
                            href="https://apartments.summerhouseatindiana.com/units/346/mccormick">Learn More
                            »</a></Button></h2>
                        <h1>THE McCORMICK</h1>
                    </div>
                    <p><i>Cottage-inspired 2 Bedroom, 2.5 Bath Townhome</i></p>
                </div>
                <Video url="https://drive.google.com/uc?id=1rYz4fDttjpFP5mmn3r1J9CZHMuADHoTn&export=download"/>
            </div>
            <div className="hero--right">
                <h1>all utilities included</h1>
                <h3><i>plus Internet & Cable</i></h3>

                <p>Simplify your life with our effortless all utilities included apartments. Rent, utilities, internet
                    and cable are rolled into a convenient bundle that can be paid with the click of a button!</p>

                <Button variant="secondary"><a
                    href="https://apartments.summerhouseatindiana.com/units/346/mccormick?contact=true">Contact
                    Us »</a></Button>
            </div>
        </section>
    );
}