import React from "react";
import {Contact, formatPhoneNumber} from "@renaissancerentals/renaissance-component";

export const FooterNav: React.FC<FooterNavProps> = (
    {handleRefToApply, handleRefToContact, handleRefToMap, contact}) => {
    return (
        <ul>
            <li><a href="#apply" onClick={handleRefToApply}>apply</a></li>
            <li><a href="#contact" onClick={handleRefToContact}>contact</a></li>
            <li><a href="#location" onClick={handleRefToMap}>location</a></li>
            <li><a href={"tel:" + contact.phone}>{formatPhoneNumber(contact.phone)}</a></li>
        </ul>
    );
}

export interface FooterNavProps {
    handleRefToApply: () => void;
    handleRefToMap: () => void;
    handleRefToContact: () => void;
    contact: Contact;
}