import React, {useState} from 'react';
import './assets/MainHeader.scss';
import {Logo} from "../logo/Logo";
import {HeaderNav} from "../nav/HeaderNav";
import {NavProps} from "../nav/NavProps";
import {Link} from "react-router-dom";
import {Button, Icon, Modal} from "@contentmunch/muncher-ui";
import {Contact, formatPhoneNumber} from "@renaissancerentals/renaissance-component";

export const MainHeader: React.FC<MainHeaderProps> = (
    {
        primaryNavs, secondaryNavs, leftLink, contact,contactClickHandler
    }
) => {

    const [showModal, setShowModal] = useState(false);


    return (
        <header className="header-main">
            <div className="div-mobile">
                <div className="header">
                    <Modal show={showModal} setShow={setShowModal}>
                        <HeaderNav primaryNavs={primaryNavs} secondaryNavs={secondaryNavs}
                                   modalClose={() => {
                                       setShowModal(false)
                                   }}/>
                        {leftLink ? <h3><Link to={leftLink.to}>{leftLink.text}</Link></h3> : ""}

                    </Modal>
                    <div className="left">
                        <div className="hamburger">
                            <Icon name="hamburger" size="medium" onClick={() => {
                                setShowModal(true);
                            }}/>
                        </div>
                    </div>
                    <Logo/>
                    <div className="right">
                        <Button onClick={contactClickHandler} variant="transparent" title="Contact us">
                            <Icon name="mail" size="small"/>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="div-main">
                <div className="header">
                    <div className="left">
                        {leftLink ? <p><Link to={leftLink.to}>{leftLink.text}</Link></p> : ""}
                    </div>
                    <HeaderNav primaryNavs={primaryNavs} secondaryNavs={secondaryNavs}
                               modalClose={() => {
                                   setShowModal(false)
                               }}>
                        <Logo/>
                    </HeaderNav>
                    <div className="right">
                        <p>Call or text today!<br/><a
                            href={"tel:" + contact.phone}>{formatPhoneNumber(contact.phone)}</a></p>
                    </div>
                </div>
            </div>
        </header>
    )
};

export interface MainHeaderProps extends NavProps {
    leftLink?: NavLink;
    contact: Contact;
    contactClickHandler: () => void;
}

export interface NavLink {
    to: string;
    text: string;
}