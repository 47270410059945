import React from 'react';
import './assets/Feature.scss';

export const Feature: React.FC<FeatureProps> = ({children}) => {
    return (
        <section className="home-section-feature">
            <div className="container">
                {children}
            </div>
        </section>
    );
}

export interface FeatureProps {
    children?: any;
}
