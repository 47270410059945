import React from 'react';
import './assets/Hero.scss';
import shortTermClock from './assets/ShortTermClock.png';
import longTermClock from './assets/LongTermClock.png';
import {Link} from "react-router-dom";
import {Button} from "@contentmunch/muncher-ui";

export const Hero = () => {
    return (
        <section className="home__section--hero">
            <div className="container">
                <h1>Welcome to SummerHouse</h1>
                <h3><i>We offer 2 types of residences</i></h3>
                <div className="sub-section">
                    <div>
                        <div className="sub-section--image">
                            <img src={shortTermClock} alt="short term clock"/>
                            <h3><Link to="/short-term">Short Term Apartments »</Link></h3>
                        </div>
                        <p>
                            A temporary residence that feels more like a home. Fully furnished and
                            equipped 1 & 2 bedroom flats & townhomes, book it for weeks or months with no binding
                            contract.
                        </p>
                        <h4 className="main">Short Term, fully furnished apartments with no lease commitment</h4>
                        <p className="main">
                            <Button variant="primary"><Link to="/short-term">click here</Link></Button>
                        </p>

                    </div>
                    <div>
                        <div className="sub-section--image">
                            <img src={longTermClock} alt="long term clock"/>
                            <h3><Link to="/long-term">Long Term Apartments »</Link></h3>
                        </div>
                        <p>
                            A traditional apartment lease with a one year contract. Fully furnished or
                            unfurnished 1, 2 & 3 bedroom flats, town homes & lofts.
                        </p>
                        <h4 className="main">Traditional <br/>apartment leases</h4>
                        <p className="main">
                            <Button variant="primary"><Link to="/long-term">click here</Link></Button>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}