import React from 'react';
import {Link} from "react-router-dom";
import logo from './assets/Logo.png';
import './assets/Logo.scss';

export const Logo: React.FC = () => {
    return (
        <div className="logo">
            <Link to="/">
                <img src={logo} alt="summer house"/>
            </Link>
        </div>
    )
};