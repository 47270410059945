import React, {useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {FloorplanSection, trackContactClicked} from "@renaissancerentals/renaissance-component";
import {MainLayout} from "../components/layout/MainLayout";
import {NavItem} from "../components/nav/NavProps";
import {PageProps} from "../data/PageProps";
import {NotFound} from "./NotFound";

export const FloorplanPage: React.FC<PageProps> = ({property, contact, isLoading}) => {
    const floorplanParam = useParams();
    const [showContactModal, setShowContactModal] = useState(false);
    const [showApplicationModal, setShowApplicationModal] = useState(false);

    const mapRef: React.Ref<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const handleRefToMap = () => {
        mapRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const primaryNavs: NavItem[] = [
        {
            anchor: "#apply",
            text: "apply",
            handleRef: () => {
                setShowApplicationModal(true);
            }
        }, {
            anchor: "#location",
            text: "location",
            handleRef: () => {
                handleRefToMap()
            }
        }
    ];
    const secondaryNavs: NavItem[] = [
        {
            anchor: "#contact",
            text: "contact",
            handleRef: () => {
                setShowContactModal(true);
                trackContactClicked("summer-house");
            }
        }
    ];

    return (
        <MainLayout contact={contact} property={property} handleRefToMap={handleRefToMap}
                    showContactModal={showContactModal} showApplicationModal={showApplicationModal}
                    primaryNavs={primaryNavs} secondaryNavs={secondaryNavs}
                    setShowApplicationModal={setShowApplicationModal} setShowContactModal={setShowContactModal}
                    contactClickHandler={() => {
                        setShowContactModal(true);
                        trackContactClicked("summer-house");
                    }}>

            {isLoading ? <></> :
                floorplanParam.floorplanId ?
                    <FloorplanSection contactClickHandler={() => {
                        setShowContactModal(true);
                        trackContactClicked("summer-house");
                    }}
                                      applyClickHandler={() => setShowApplicationModal(true)}
                                      floorplanId={floorplanParam.floorplanId} locationRef={mapRef}
                                      handleRefToLocation={handleRefToMap}/> : <NotFound/>}

        </MainLayout>
    );
}

export interface FloorplanParam {
    floorplanId: string
}
